import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import iconDL from "../images/download.svg"

function ContribuerPage(props: []) {
  useEffect(() => {
    document.body.className = "page-presse"
  }, [])

  return (
    <Layout contentClassName="container">
      <SEO 
      title="Découvrez le kit presse et les articles sur Shift Your Job"
      description="Dossier de presse, logos, illustrations...
      Découvrez les éléments clé à télécharger pour parler du projet
      La presse parle de Shift Your Job"
       gtmPageType="presse" 
       gtmPageName="presse" />
      <div className="mx-2 py-16 md:mx-auto md:max-w-2xl lg:max-w-3xl text-primary">
        <h1 className="text-3xl md:text-5xl leading-none text-primary font-black mb-8">
          Presse
        </h1>
        {/* <p>
          Nous avons classé et organisé les organisations retenues autour de 11
          secteurs et de plus de 300 sous-secteurs. Découvrez la vue synthétique
          de la cartographie des secteurs sur ses deux premiers niveaux.
        </p> */}
        <br />
        <div className="">
          <div>A télécharger ici :</div>
          <br />
          <h4 className="text-lg font-bold">DOSSIER DE PRESSE</h4>
          <a
            href="/shiftyourjob_dossier-de-presse.pdf"
            target="_blank"
            className="flex items-center mb-5 mt-5 no-underline"
          >
            <img src={iconDL} alt="" width="24" height="24" className="mr-2" />
            <div className="custom-underline">
            shiftyourjob_dossier-de-presse.pdf
            </div>
          </a>
          <h4 className="text-lg font-bold">LOGOS</h4>
          <a
            href="/shiftyourjob_package_logo.zip"
            
            className="flex items-center mb-5 mt-5 no-underline"
          >
            <img src={iconDL} alt="" width="24" height="24" className="mr-2" />
            <div className="custom-underline">
              shiftyourjob_package_logo.zip
            </div>
          </a>
          <h4 className="mt-10 text-lg font-bold">ILLUSTRATIONS</h4>

          <a
            href="/shiftyourjob_illustrations.zip"
           
            className="flex items-center mb-5 mt-5 no-underline"
          >
            <img src={iconDL} alt="" width="24" height="24" className="mr-2" />
            <div className="custom-underline">
              shiftyourjob_illustrations.zip
            </div>
          </a>

          <p className="mt-10 markdown">
            Pour nous contacter, vous pouvez utiliser le <a href="/contact">formulaire de contact</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default ContribuerPage
